.tabs {
    position: relative;
    min-height: 340px;
    /* This part sucks */
    clear: both;
    margin: 35px 0 25px;
    background: white;
}

.tab {
    float: left;

}

.tab label {
    background: #eee;
    padding: 10px;
    border: 1px solid #ccc;
    margin-left: -1px;
    position: relative;
    left: 1px;
    top: -29px;
    -webkit-transition: background-color .17s linear;
}

.tab [type=radio] {
    display: none;
}

.content_tab {
    position: absolute;
    top: -5px;
    left: 0;
    background: white;
    right: 0;
    bottom: 0;
    padding: 20px;
    border: 1px solid #ccc;
    -webkit-transition: opacity .6s linear;
    /*opacity: 0;*/
}

[type=radio]:checked~label {
    background: white;
    border-bottom: 1px solid white;
    z-index: 2;
}

[type=radio]:checked~label~.content_tab {
    z-index: 1;
    opacity: 1;
}

.content_tab ul li {
    height: 34px;
    line-height: 34px;
}
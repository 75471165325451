/* CSS Document */

/* *{font-family: Arial;}
html, body{background:#fff;margin: 0px;} */
#signin_bar { width:100%; height:42px; background:#323232; }
#image_pretzel_os { margin: 0 auto; width:800px; height:42px; }
#image_pretzel_os img { margin: 50px 5px 5px 25px; }
.wrapper_sign_in{
	height: auto !important;
	margin: 60px auto 0;
	position: inherit;
	text-align: left;
	width: 800px;
	z-index: -1;
	background:none;
}

.wrapper_sign_in #inline1{
	background:#FFF;
	text-align:left;
	border: 1px solid #e6e6e6;
	-moz-border-radius: 10px; /* Firefox*/ 
	-ms-border-radius: 10px; /* IE 8.*/ 
	-webkit-border-radius: 10px; /* Safari,Chrome.*/ 
	border-radius: 10px; /* El estÃ¡ndar.*/
}

.wrapper_sign_in .loginform{
	margin: 30px 200px;
	height:215px;
}

.wrapper_sign_in h1{
	color:#000;
	font-size:24px;
	font-weight:bold;
	text-align:left;
	margin: 0 0 10px 0;
}

.wrapper_sign_in #login_button{
	color: #FFF;
	background: #0092f8;
	height:30px;
	border: 1px solid;
	width:70px;
	font-weight:bold;
}

.wrapper_sign_in #login_button:hover {
  background: #0074c5; 
}

.wrapper_sign_in #login_button[disabled]:active, #login_button[disabled]{
  background: #AADCFF;
  border: none;
  text-shadow: none;
}

.wrapper_sign_in #login_button:focus {
  outline: 0;
}

.wrapper_sign_in .input_text{
	height:30px;
	padding-left:7px;
	border: 1px solid;
	border-color: #E6E6E6;
        /*line-height: 30px\0/;*/
}

@media screen\0 {
	.wrapper_sign_in .input_text{
    line-height: 30px;
  }
}

.wrapper_sign_in .input_text:focus{
    outline: none;
    border-color: #000;
}

.wrapper_sign_in #footer_signin{ 
	width:100%;
	height:42px;
	background:#f4f4f4;
	-moz-border-radius: 0 0 10px 10px; /* Firefox*/ 
	-ms-border-radius: 0 0 10px 10px; /* IE 8.*/ 
	-webkit-border-radius: 0 0 10px 10px; /* Safari,Chrome.*/ 
	border-radius: 0 0 10px 10px; /* El estÃ¡ndar.*/
}

.wrapper_sign_in #footer_signin p{
	padding: 13px 0 0 0;
	color:#7e7e7e;
	font-size:12px;
	text-align:center;
}

.wrapper_sign_in #user_error{
	display:none;
	color:#F00;
        font-size: 12px;
}

.wrapper_sign_in #pass_error{
	display:none;
	color:#F00;
        font-size: 12px;
}

.wrapper_sign_in #header_signin_warning{
	background-color: #1F2555;
	color: #FFF;
	width: 100%;
	height: 76px;
	border-radius: 10px 10px 0 0;
}

.wrapper_sign_in #header_signin_warning div{
	padding: 13px 0 0 0;
	font-size: 12px;
	text-align: center;
}

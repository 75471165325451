body {text-align: center; margin:0; background-color:#f6f6f6; /*color: #3A3939;*/color: #231F20;font-size: 12px;}
* {font-family: Arial;}
td {vertical-align:top;}
/*hr{border: 1px solid #E6E6E6;}*/
hr{
    border-bottom: 1px solid #acdae7;
    border-top: none;
    border-right: none;
    border-left: none;
    float: left;
    left: -10px;
    margin: 0;
    position: relative;
    top: 10px;
    width: 574px;
}
a { text-decoration:none; color:#0092f8;}
a:hover {text-decoration: underline;color: #0065ab;}
h1,h2,h3 { color:#F89728; margin-top: 0px;}
th{text-align: center;}
#home{color: #4FB3CF;font-size:15px;}
#home:hover{color: #F89728;}

.mandatory{border-left: 2px solid #EE0000;}

input{
    height:30px;
    padding-left:7px;
    border: 1px solid;
    border-color: #E6E6E6;
    width: 250px;
    color: #231F20;
    /*line-height: 20px\0/;*/
}
@media screen\0 {input{line-height: 20px;}}
input:focus{
    outline: none;
    /*border-color: #000;*/
    border: 1px solid #231F20;
}
textarea{
    border: 1px solid;
    border-color: #E6E6E6;
    color: #231F20;
}
textarea:focus{
    outline: none;
    /*border-color: #000;*/
    border: 1px solid #231F20;
}
select{border:1px solid #E6E6E6;width:200px;height: 33px;width: 260px;color: #231F20;}
select:focus{outline: none;}

input::selection {
  background-color:#4FB3CF;
  color:#fff;
}
input::-moz-selection {
  background-color:#4FB3CF;
  color:#fff;
}
input::-webkit-selection {
  background-color:#4FB3CF;
  color:#fff;
}

.content_layout
{
    position:relative;
    float:left;
    width:100%;
    height:auto;
}

#load_header{ height:90px; background: #D4E6F2; margin-bottom: 30px;border-top: 4px solid #0D4891;}
/* #content{ width:840px; margin:0 auto; background-color:#FFF;min-height: 685px !important;} */
#content{ 
    width:840px; 
    margin:0 auto; 
    background-color:#FFF;
    min-height: 100%;
    overflow-y: hidden;
    position: relative;
}
#load_content{ width:660px; margin:0 0 0 auto; background-color:#FFF;float: right;min-height: 500px;}
#load_menu{ width:180px; margin:0 auto; background-color:#FFF;height: 150px !important;float: left;}
#load_footer{ height:50px; width:900px; margin:0 auto;}

#inner-header{ margin:0 auto; width:900px; height:90px; }
#inner-menu{ 
    margin:0 auto; 
    height: 100%;
    border: 1px solid #D4E6F2;
    -moz-border-radius: 5px; /* Firefox*/ 
    -ms-border-radius: 5px; /* IE 8.*/ 
    -webkit-border-radius: 5px; /* Safari,Chrome.*/ 
    border-radius: 5px; /* El estÃ¡ndar.*/
}
#logo{ width:240px; height:60px; float:left; margin:25px 45px 20px 0;}
#account{ width:540px; height:90px; float:right; text-align:right;}
#account span{ margin:8px 12px; float:right; }

#menu{ width:178px; float:left;}
#menu ul{ display:block; margin:0; padding:0; float:right; text-align:left; list-style:none;}
#menu ul li{ display:inline; margin:0; float:left; height:30px; width:178px !important;line-height: 30px;}
@media screen\0 {#menu ul li{width: 89px;}}
/* #menu ul li:last-child{ margin-right:0; } */
#menu ul li:hover{background-color: #DEF0F5;}
#menu ul li span{cursor: pointer; font-weight: normal !important; color: #0D4891;float: left;width: 168px;height: 30px;margin-left: 10px;text-decoration: none;}

#title_menu{
    height: 30px;
    width: 158px;
    background: #0D4891;
    color: #FFF;
    float: left;
    line-height: 30px;
    padding: 0 10px;
    text-align: left;
    
    -moz-border-radius: 4px 4px 0 0; /* Firefox*/ 
    -ms-border-radius: 4px 4px 0 0; /* IE 8.*/ 
    -webkit-border-radius: 4px 4px 0 0; /* Safari,Chrome.*/ 
    border-radius: 4px 4px 0 0; /* El estÃ¡ndar.*/
}

.border_content{
    margin:0 auto 20px; 
    min-height: 282px;
    overflow: auto;
    border: 1px solid #D4E6F2;
    -moz-border-radius: 5px; /* Firefox*/ 
    -ms-border-radius: 5px; /* IE 8.*/ 
    -webkit-border-radius: 5px; /* Safari,Chrome.*/ 
    border-radius: 5px; /* El estÃ¡ndar.*/
    float: left;
}

#new_invoice ul{list-style: none;float: left;margin: 0;}
#new_invoice ul li{float: left;margin: 0 0 3px 0;width: 556px;}
#new_invoice ul li:nth-child(even){margin: 0 0 12px 0;}
#new_invoice ul li:first-child{margin: 0 0 3px 0;}
#new_invoice ul li:last-child{margin: 3px 0 0 0;}

/***************changes for the customers list using a list instead of a table************/
#invoice_list{list-style: none !important;float: left !important;margin: 0 !important;padding: 0 !important;}
#invoice_list li{float: left !important;margin: 10px 12px 11px 10px !important;}
#invoice_list li span{float: left;width: 120px;text-align: left;margin-left: 17px;}
#invoice_list{float: left;}
#invoice_list tbody{float: left;margin: -2px;padding: 0;width: 574px;}
#invoice_list tr{float: left !important;margin: 10px; width: 554px;}
#invoice_list tr.bottom_line_hr{
    border-bottom: 1px solid #acdae7;
    border-top: none;
    border-right: none;
    border-left: none;
    margin: 0;
    width: 574px;}
#invoice_list tr td{float: left;width: 120px;text-align: left;margin-left: 17px;padding: 0;}

#new_customer ul{list-style: none;float: left;margin: 0;}
#new_customer ul li{float: left;margin: 0 0 7px 0;width: 556px;}
/* #new_customer ul li:nth-child(even){margin: 0 0 5px 0;}
#new_customer ul li:first-child{margin: 0 0 3px 0;} */
#new_customer ul li:last-child{margin: 0;}

#edit_customer ul{list-style: none;float: left;margin: 0;}
#edit_customer ul li{float: left;margin: 0 0 5px 0;width: 556px;line-height: 30px;}
#edit_customer ul li:last-child{margin: 15px 0 0 0;}

.sub-titles-balance{
    width: 166px !important;
    background-color: #D4E6F2 !important;
    border-radius:0px !important;
    text-align: center !important;
    color: #0D4891 !important;
}

.balance-subsection{
    float: left;
    width: 100%;
}
.balance-subsection p{
    float: left;
    text-align: center;
    margin-left: 10px;
}
.balance-subsection p span{
    float: left;
    text-align: left;
    width: 150px;
}
.balance-subsection p span b{
    float: right;
}

.balance-subsectioni{
    float: left;
}
.balance-subsectioni p{
    float: left;
    text-align: center;
    margin-left: 10px;
    margin: 5px;
}
.balance-subsectioni p span{
    float: left;
    text-align: left;
    /*width: 200px;*/
}
.balance-subsectioni p span b{
    float: right;
    color: #0D4891;
}

.mount_right{
    text-align: right !important;
    width: 85px !important;
    margin-right: 35px;
}

.mount_right_table{
    text-align: right !important;
    width: 155px !important;
    margin-right: 35px;
}

.link{
    text-decoration: none;
    color: #0092f8;
    cursor: pointer;
}

#number_orders
{
    color: #A95100;
    width: 28px;
    position: absolute;
    margin-left: 25px;
    top: 11px;
    font-size: 11px;
    font-weight: bold;
}
@media screen and (-webkit-min-device-pixel-ratio:0){#number_orders{top: 11px;/*GC*/}}
@media screen\0 {#number_orders{top: 12px;}}

#account ul{
    display:block;
    margin: 3px 12px 0 12px;
    padding:0;
    float:right;
    text-align:center;
    list-style:none;}

.wrapper{text-align:left;/*margin:auto;height: auto !important;min-height: 400px;z-index: -1;margin-bottom:20px;float: left;*/}
.content{padding:20px 30px 30px 30px;}
.inner_container{padding:0 0 0 30px;float: left;}

#title_invoice{
    height: 30px;
    width: 608px;
    background: #0D4891;
    color: #FFF;
    float: left;
    line-height: 30px;
    padding: 0 10px;
    text-align: left;
    
    -moz-border-radius: 4px 4px 0 0; /* Firefox*/ 
    -ms-border-radius: 4px 4px 0 0; /* IE 8.*/ 
    -webkit-border-radius: 4px 4px 0 0; /* Safari,Chrome.*/ 
    border-radius: 4px 4px 0 0; /* El estÃ¡ndar.*/
}

#title_invoices{
    height: 30px;
    width: 554px;
    background: #0D4891;
    color: #FFF;
    float: left;
    line-height: 30px;
    padding: 0 10px;
    text-align: left;
    
    -moz-border-radius: 4px 4px 0 0; /* Firefox*/ 
    -ms-border-radius: 4px 4px 0 0; /* IE 8.*/ 
    -webkit-border-radius: 4px 4px 0 0; /* Safari,Chrome.*/ 
    border-radius: 4px 4px 0 0; /* El estÃ¡ndar.*/
}

#title_invoices span{
    float: left;
    text-align: left;
    width: 120px;
    margin-left: 17px;
}
#title_invoices span:last-child{
    text-align: right;
    width: 85px;
    margin-left: 17px;
    margin-right: 37px;
}

#my-beverages-table {float:left;}
#my-seasonings-table {float:right;}
#my-beverages-table img {margin-top:10px;}
#my-seasonings-table img {margin-top:10px;}
#my-beverages-table th {text-align:center;font-size:24px;font-weight:lighter;}
#my-seasonings-table th {text-align:center;font-size:24px;font-weight:lighter;}

.submenu_options
{
    color: #fff;
    line-height:30px;
    margin-top:13px;
    position: absolute;
    z-index: 1;
    background: #D4E6F2;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    -webkit-border-top-left-radius: 0;
    -moz-border-radius-topleft: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    -webkit-box-shadow: 1px 1px 1px rgba(0,0,0,0.3);
    -moz-box-shadow: 1px 1px 1px rgba(0,0,0,0.3);
    box-shadow: 1px 1px 1px rgba(0,0,0,0.3);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding-box;
    background-clip: padding-box;
}
.submenu_options p{float: left;height: 30px;color: #000; display: inline;}
.submenu_options p:hover{font-weight: bold;text-decoration: none; cursor: pointer;}

#profile{width:91px;margin-left:-7px;margin-top: 24px;}
#product_menu{display:none;width:94px;margin-left:7px;}
#order_menu{display:none;width:122px;margin-left:17px;}
#shipping_menu{display:none; width:100px;margin-left:8px;}
#shipping_menu .submenu_option{width:85px;}
/*
#shipping_menu .submenu_option:hover{
    color: #ddd;
    background: rgba(255,255,255,0.1);
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}*/
#locator_menu{display:none; width:98px;margin-left:15px;}
#settings_menu{display:none; width:100px;margin-left:15px;}
#settings_menu .submenu_option{width:60px;}

.submenu_option{float:left; margin:0 5px 5px 13px; width:105px; text-align:left; }
.active{background-color: #E4F0F4;}

.one{ display:none;}
.two{ display:none;}

#navigation{color:#4FB3CF !important;}
#navigation:hover{color:#F78F1E !important;}

.number { text-align:right; }
.centered { text-align:center; }

#my-account-table {float:left; text-align:left;margin: 20px 0 20px 70px;}
#my-account-pref {text-align:left; margin: 20px 0 20px 0;}

.alignment_right {text-align: right;}
.alignment_left {text-align: left;}
.alignment_center {text-align: center;}

.sorttable_nosort{ cursor:default !important; }
.shipping-links{color:GRAY;text-decoration:underline;float:left;margin-top:-10px;font-size:18px;}

#login_button{
    color: #FFF;
    background: #0092f8;
    height:30px;
    border: 1px solid;
    width:70px;
    font-weight:bold;
}
.login_button{
    color: #FFF;
    background: #0092f8;
    height:30px;
    border: 1px solid;
    /*width:auto;*/
    font-weight:bold;
}
.login_button:hover {background: #0074c5; } 
#login_button:hover {background: #0074c5; }
.login_button:focus {outline: 0;border: 0px none #FFF;}
#login_button:focus {outline: 0;}
.login_button[disabled]:active {background: #AADCFF;}
.login_button[disabled]{background: #AADCFF;}
#login_button[disabled]:active {background: #AADCFF;}
#login_button[disabled]{background: #AADCFF;}

.product_detail_text{color:#4FB3CF; cursor: pointer;}
.product_detail_text:hover{color:#F78F1E;}
.product_detail p{margin:20px 0 20px 0;}

#back_link{color:#4FB3CF;}
#back_link:hover{color:#F78F1E;}

#box_border{
    float: right;
    padding: 10px;
    margin-top: 81px;
    width: 94%;
    border: 1px solid #e6e6e6;
    -moz-border-radius: 10px; /* Firefox*/ 
    -ms-border-radius: 10px; /* IE 8.*/ 
    -webkit-border-radius: 10px; /* Safari,Chrome.*/ 
    border-radius: 10px; /* El estÃ¡ndar.*/
}
.box_border{
    border: 1px solid #e6e6e6;
    -moz-border-radius: 10px; /* Firefox*/ 
    -ms-border-radius: 10px; /* IE 8.*/ 
    -webkit-border-radius: 10px; /* Safari,Chrome.*/ 
    border-radius: 10px; /* El estÃ¡ndar.*/
}

.input_middle_size{width: 120px;}

.input_long_size{width: 250px;/*FF*/}
@media screen and (-webkit-min-device-pixel-ratio:0){.input_long_size{width: 250px;/*GC*/}}

.search_long_size{width: 240px;/*FF*/}
@media screen and (-webkit-min-device-pixel-ratio:0){.search_long_size{width: 241px;/*GC*/}}

.profile_labels{width:15%;line-height: 23px;}
#email{width: 292px;}
@media screen and (-webkit-min-device-pixel-ratio:0){#email{width: 312px;/*GC*/}}
@media screen\0 {#email{width: 320px;}}

#alert_box{border: 1px solid #ffd200;padding: 20px;margin-right: 3px;background: #fffdc7;margin-top: 5px;width: 300px;}
/* @media screen and (-webkit-min-device-pixel-ratio:0){#alert_box{width: 330px;/*GC*/ /*}} */

#user_information_account
{
    width: auto;
    height: 30px;
    float: right;
    margin-top: 30px;
    
    -moz-border-radius: 0 0 6px 6px; /* Firefox*/ 
    -ms-border-radius: 0 0 6px 6px; /* IE 8.*/ 
    -webkit-border-radius: 0 0 6px 6px; /* Safari,Chrome.*/ 
    border-radius: 0 0 6px 6px; /* El estÃ¡ndar.*/
    /*
    background: #F89728;
    background: -moz-linear-gradient(#F89728, #DF7A1C);
    background: -webkit-linear-gradient(#F89728,#DF7A1C);
    background: -o-linear-gradient(#F89728,#DF7A1C);
    background: -ms-linear-gradient(#F89728,#DF7A1C);
    background: linear-gradient(#F89728,#DF7A1C);*/
}

#shipping_section{width: 300px;position: relative;right: -355px;top: -256px;}
@media screen and (-webkit-min-device-pixel-ratio:0){#shipping_section{top: -254px;/*GC*/}}
#billing_section{width: 300px;margin-left: 30px;}

#customer_sectionR{width: 300px;position: relative;right: -355px;top: -306px;}
@media screen and (-webkit-min-device-pixel-ratio:0){#customer_sectionR{top: -306px;/*GC*/}}
#customer_sectionL{width: 300px;margin-left: 30px;}

.select_middle_size{width: 173px;}
.select_long_size{width: 265px;}
@media screen and (-webkit-min-device-pixel-ratio:0){.select_long_size{width: 262px;/*GC*/}}
#locator_result_table{margin-left: 18px;}

#search_locator_result_hours{
    float: right;
    left: -100px;
    position: relative;
    top: -78px;
}

.link_stores{
    color: #4FB3CF;
}

.list_input{margin-bottom: -9px;position: relative;top: -4px;width: 75px;font-size: 12px;text-align: right;}

.showMore{
    cursor: pointer;
    margin: 5px 257px !important;
    width: 40px !important;
    height: 12px !important;
    padding: 8px;
    text-align: center;
}

#footer_signin{ 
    width:100%;
    float: left;
}
#footer_signin p{
    margin: 0px;
    font-size:12px;
    text-align:center;
}

#mandatory_fields{
    color: red;
    margin: 8px 0 0 0;
    font-weight: bold !important;
}

.contenttooltip {
    width: 70px;
    float: left;
}

.contenttooltip .tooltip {
    background: #1496bb;
    color: #fff;
    display: block;
    width: 225px;
    margin-bottom: 15px;
    opacity: 0;
    padding: 15px;
    pointer-events: none;
    position: absolute;

    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -ms-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
    -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
}

/* This bridges the gap so you can mouse into the tooltip without it disappearing */
.contenttooltip .tooltip:before {
    bottom: -20px;
    content: " ";
    display: block;
    height: 20px;
    left: 0;
    position: absolute;
    width: 100%;
}

/* CSS Triangles - see Trevor's post */
.contenttooltip .tooltip:after {
    border-left: solid transparent 10px;
    border-right: solid transparent 10px;
    border-top: solid #1496bb 10px;
    bottom: -10px;
    content: " ";
    height: 0;
    left: 15%;
    margin-left: -13px;
    position: absolute;
    width: 0;
}

.contenttooltip:hover .tooltip {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
}

/* IE can just show/hide with no transition */
.lte8 .contenttooltip .tooltip {
    display: none;
}

.lte8 .contenttooltip:hover .tooltip {
    display: block;
}
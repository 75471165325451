.basic-modal-content {display:none;}
/* Overlay */
#simplemodal-overlay {background-color:#000;opacity: 0.0 !important;}
/* Container */
#simplemodal-container {height:322px; width:780px; background-color:#333; border:4px solid #444; padding:12px;}
@media screen and (-webkit-min-device-pixel-ratio:0){#simplemodal-container {height:322px;}}
#simplemodal-container .simplemodal-data {padding:8px;}
#simplemodal-container code {background:#141414; border-left:3px solid #65B43D; color:#bbb; display:block; font-size:12px; margin-bottom:12px; padding:4px 6px 6px;}
#simplemodal-container a {color:#ddd;}
#simplemodal-container a.modalCloseImg {background:url(/src/images/basic/x.png) no-repeat; width:25px; height:29px; display:inline; z-index:3200; position:absolute; top:-15px; right:-16px; cursor:pointer;}
#simplemodal-container h3 {color:#F89628;font-size: 14px;}

table.orders_list {border-collapse: collapse;border:none;}
table.orders_list a{color:#4FB3CF;}
table.orders_list a:hover{color:#F78F1E;}
table.orders_list th {padding: 5px; background: none repeat scroll 0 0 #F89728; color: #FFFFFF;font-weight:normal;}
table.orders_list td {padding:10px;border:1px solid #E6E6E6; border-width:0px 1px;}
table.orders_list td.product {width: 20%; border-bottom-width:0px; border-top-width:0px;}
table.orders_list td.product a:hover {color:#F78F1E;}
table.orders_list td.price {text-align: left; padding-right: 25px; width: 20%;}
table.orders_list td.productid {text-align: right; width: 20%;}
table.orders_list td.nav {text-align: center; border-top-color: #E6E6E6; border-top-width: 1px; border-top-style: solid;}
iframe#content_frame {width: 800px; height: 800px;}

#product_detail{text-align:center;width:100%;border: 1px solid #FFF;border-collapse:collapse;}
#product_detail th{border: 1px solid #FFF;border-collapse:collapse;}
#product_detail td{border: 1px solid #FFF;border-collapse:collapse;}
#total_separate{width:100px;float:right;margin-right:25px;text-align:right;float:none\9;}
/* :root #total_separate{ float:right;\0/IE9; }  IE9 */
#section_total{width:200px;margin-left:530px;height:90px;}
#section_customer{width:250px;margin-left:458px;height:68px;}
.label_total{float:right;height:20px;margin: 5px 0;}
.label_customer{float:right;height:20px;margin: 2px 0;}
#total_cseparate{width:100px;float:right;text-align:right;float:none\9;}
/* :root #total_cseparate{ float:right;\0/IE9; }  IE9 */